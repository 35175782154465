@font-face {
  font-family: 'Betterlove';
  src: local('Betterlove'), url('assets/fonts/Betterlove.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'CaviarDreams';
  src: local('CaviarDreams'), url('assets/fonts/CaviarDreams.ttf') format('truetype');
  font-display: swap;
}

:root {
  --col-dark: #231F20;
  --col-dark-1: #504346;

  --col-light: #ffffff;
  --col-light-1: #ebebeb;
  --col-light-2: #c0c0c0;
  --col-light-3: #949494;

  --col-teal: #144E52;
  --col-teal-1: #367c81;
}

html {
  font-size: 20px;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'CaviarDreams';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white; 
  /* background-image: linear-gradient(135deg, #dad4ec 0vh, #dad4ec 1vh, #f3e7e9 100vh); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, .fs-1 { margin: 0; font-weight: normal; }
h2, .fs-3 { margin: 0; font-weight: normal; }
h3, .fs-3 { margin: 0; font-weight: normal; }
h4, .fs-4 { margin: 0; font-weight: bold; }
h5, .fs-5 { margin: 0; font-weight: bold; }

h1, h2, h3, h4, h5,
.fs-1, .fs-2, .fs-3, .fs-4, .fs-5 {
  font-family: 'Betterlove';
}

p {
  line-height: 28px;
}

a {
  text-decoration: none;
  color: var(--col-teal);
  font-weight: bold;
}
a.link {
  font-size: 0.8em;
  line-height: 0.8em;
}

.bg-dark { background-color: var(--col-dark) !important; }
.text-dark { color: var(--col-dark) !important; }
.bg-light { background-color: var(--col-light) !important; }
.text-light { color: var(--col-light) !important; }

.border-box {
  box-sizing: border-box;
}

.container-large {
  max-width: 1600px;
  margin: auto;
  box-sizing: border-box;
}

.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  opacity: 0.5;
}